import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import { AuthState } from "store/auth/auth.types"
import { User } from "types/common.types"
import { LOCAL_STORAGE } from "utils/authentication-constants.utils"

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE.API_TOKEN),
  user: undefined,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { authMode, apiToken } = action.payload
      state.isAuthenticated = true
      state.authMode = authMode
      state.apiToken = apiToken
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    reset: (state) => {
      state.isAuthenticated = false
      state.user = undefined
      state.authMode = undefined
      state.apiToken = undefined
    },
  },
})

export const { login, reset, setUser } = authSlice.actions
export default authSlice.reducer
