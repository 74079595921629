import React, { useEffect, useState } from "react"

import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { useIntl } from "react-intl"
import { Route, Routes } from "react-router-dom"

import api from "api/api"
import { AbsoluteLoader } from "components/loader"
import DefaultLayout from "layouts/Default"
import Agenda from "screens/agenda"
import Application from "screens/application"
import Details from "screens/details"
import Password from "screens/password/Password"
import Search from "screens/search"
import Validation from "screens/validation/Validation"
import { useReduxDispatch } from "store"
import actions from "store/actions"
import { getErrorMessages } from "utils/error.utils"

const AppRouter: React.FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  const dispatch = useReduxDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await api.user.init()
        dispatch(actions.auth.setUser(data))
      } catch (e) {
        const messages = getErrorMessages(e as AxiosError)
        messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <AbsoluteLoader />
  }

  return (
    <DefaultLayout>
      <Routes>
        <Route path={intl.formatMessage({ id: "link.app" })} element={<Application />} />
        <Route path={intl.formatMessage({ id: "link.validateAccount" })} element={<Validation />} />
        <Route path={intl.formatMessage({ id: "link.activateAccount" })} element={<Password reset={false} />} />
        <Route path={intl.formatMessage({ id: "link.editPassword" })} element={<Password reset={true} />} />
        <Route path={intl.formatMessage({ id: "link.details" })} element={<Details />} />
        <Route path={intl.formatMessage({ id: "link.planning" })} element={<Agenda />} />
        <Route path="*" element={<Search />} />
      </Routes>
    </DefaultLayout>
  )
}

export default AppRouter
