export enum FILTER_TYPES {
  TERM = "TERM",
  RANGE_NUMBER = "RANGE_NUMBER",
  TERM_SEARCH = "TERM_SEARCH",
  RANGE_DATE = "RANGE_DATE",
}

export enum FILTERS {
  CONTRACT_TYPE = "contractType",
  DATE = "date",
}

export enum EXPERTS {
  DEKRA = "DEKRA",
  RENAULT = "RENAULT",
}

export enum USER_TYPE {
  DEALER = "DEALER",
  EXPERT = "EXPERT",
  DEALER_ADMIN = "DEALER_ADMIN",
  EXPERT_ADMIN = "EXPERT_ADMIN",
  ADMIN = "ADMIN",
}

export enum RESTITUTION_STATUS {
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
  WAITING_VALIDATION = "WAITING_VALIDATION",
  VALIDATED = "VALIDATED",
  PRE_CLOSED = "PRE_CLOSED",
  DONE = "DONE",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
  TO_COMPLETE = "TO_COMPLETE",
}

export enum DOCUMENT_STATUS {
  PRESENT = "PRESENT",
  NOT_DELIVERED = "NOT_DELIVERED",
  ABSENT = "ABSENT",
}

export enum RECEPTION_TYPE {
  EXPERTISE = "EXPERTISE",
  RECEPTION = "RECEPTION",
}

export enum VEHICLE_TYPE {
  VU = "VU",
  VP = "VP",
}

export enum DamageZones {
  "LEFT_REAR_WHEEL" = "LEFT_REAR_WHEEL",
  "LEFT_REAR_FENDER" = "LEFT_REAR_FENDER",
  "LEFT_REAR_DOOR" = "LEFT_REAR_DOOR",
  "LEFT_REAR_WINDOW" = "LEFT_REAR_WINDOW",
  "LEFT_FRONT_WINDOW" = "LEFT_FRONT_WINDOW",
  "LEFT_FRONT_DOOR" = "LEFT_FRONT_DOOR",
  "LEFT_SIDE_SILL" = "LEFT_SIDE_SILL",
  "LEFT_FRONT_FENDER" = "LEFT_FRONT_FENDER",
  "LEFT_FRONT_WHEEL" = "LEFT_FRONT_WHEEL",
  "LEFT_REAR_VIEW_MIRROR" = "LEFT_REAR_VIEW_MIRROR",
  "REAR_BUMPER" = "REAR_BUMPER",
  "LEFT_REAR_HEADLIGHT" = "LEFT_REAR_HEADLIGHT",
  "REAR_LICENSE_PLATE" = "REAR_LICENSE_PLATE",
  "RIGHT_REAR_HEADLIGHT" = "RIGHT_REAR_HEADLIGHT",
  "TRUNK" = "TRUNK",
  "REAR_WINDSHIELD" = "REAR_WINDSHIELD",
  "ROOF" = "ROOF",
  "FRONT_WINDSHIELD" = "FRONT_WINDSHIELD",
  "HOOD" = "HOOD",
  "LEFT_FRONT_HEADLIGHT" = "LEFT_FRONT_HEADLIGHT",
  "GRILL" = "GRILL",
  "FRONT_RIGHT_HEADLIGHT" = "FRONT_RIGHT_HEADLIGHT",
  "LEFT_FRONT_BUMPER" = "LEFT_FRONT_BUMPER",
  "FRONT_BUMPER" = "FRONT_BUMPER",
  "RIGHT_FRONT_BUMPER" = "RIGHT_FRONT_BUMPER",
  "RIGHT_REAR_VIEW_MIRROR" = "RIGHT_REAR_VIEW_MIRROR",
  "SPARE_WHEEL" = "SPARE_WHEEL",
  "RIGHT_REAR_FENDER" = "RIGHT_REAR_FENDER",
  "RIGHT_REAR_WHEEL" = "RIGHT_REAR_WHEEL",
  "RIGHT_REAR_DOOR" = "RIGHT_REAR_DOOR",
  "RIGHT_REAR_WINDOW" = "RIGHT_REAR_WINDOW",
  "FRONT_RIGHT_WINDOW" = "FRONT_RIGHT_WINDOW",
  "RIGHT_FRONT_DOOR" = "RIGHT_FRONT_DOOR",
  "RIGHT_ROCKER_PANEL" = "RIGHT_ROCKER_PANEL",
  "RIGHT_FRONT_FENDER" = "RIGHT_FRONT_FENDER",
  "RIGHT_FRONT_WHEEL" = "RIGHT_FRONT_WHEEL",
  "UNDEFINED" = "UNDEFINED",
  "INSIDE" = "INSIDE",
  "RIGHT_REAR_BUMPER" = "RIGHT_REAR_BUMPER",
  "LEFT_REAR_BUMPER" = "LEFT_REAR_BUMPER",
}

export enum CONFIG {
  CLIENT_ID_ARCA = "CLIENT_ID_ARCA",
  CLIENT_ID_RNET = "CLIENT_ID_RNET",
  REDIRECT_URI = "REDIRECT_URI",
  AUTHORIZATION_ENDPOINT = "AUTHORIZATION_ENDPOINT",
  TOKEN_ENDPOINT = "TOKEN_ENDPOINT",
  REQUESTED_SCOPES_ARCA = "REQUESTED_SCOPES_ARCA",
  REQUESTED_SCOPES_RNET = "REQUESTED_SCOPES_RNET",
  ACR_VALUES_ARCA = "ACR_VALUES_ARCA",
  ACR_VALUES_RNET = "ACR_VALUES_RNET",
  API_BASE_HOSTNAME = "API_BASE_HOSTNAME",
  API_PORTAL_BASE_URL = "API_PORTAL_BASE_URL",
  API_PUBLIC_BASE_URL = "API_PUBLIC_BASE_URL",
  API_GATEWAY_KEY_PORTAL = "API_GATEWAY_KEY_PORTAL",
  API_GATEWAY_KEY_PUBLIC = "API_GATEWAY_KEY_PUBLIC",
  RECAPTCHA_KEY = "RECAPTCHA_KEY",
  DEKRA_PORTAL_URL = "DEKRA_PORTAL_URL",
  DEV_TOKEN = "DEV_TOKEN",
  ENV = "ENV",
  ENABLE_IDP = "ENABLE_IDP",
  CLIENT_ID_OKTA = "CLIENT_ID_OKTA",
  REDIRECT_URI_OKTA = "REDIRECT_URI_OKTA",
  REQUESTED_SCOPES_OKTA = "REQUESTED_SCOPES_OKTA",
  TOKEN_ENDPOINT_OKTA = "TOKEN_ENDPOINT_OKTA",
  AUTHORIZATION_ENDPOINT_OKTA = "AUTHORIZATION_ENDPOINT_OKTA",
}

export enum WINDOW_PROPERTIES {
  CONFIGURATION = "CONFIGURATION",
}

export enum TIRE_SEASON {
  ALL_SEASON = "ALL_SEASON",
  WINTER = "WINTER",
  SUMMER = "SUMMER",
}

export enum CLEANLINESS {
  CLEAN = "CLEAN",
  TODO = "TODO",
}

export enum RECAPTCHA_ACTIONS {
  VALIDATION = "VALIDATION",
  ACTIVATE = "ACTIVATE",
  RESET_PASSWORD = "RESET_PASSWORD",
  LOGIN = "LOGIN",
  IOS_CODE = "IOS_CODE",
}

export enum ERRORS {
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  NOT_FOUND = "NOT_FOUND",
  KEY_NOT_FOUND = "KEY_NOT_FOUND",
  CLIENT_BLOCKED = "CLIENT_BLOCKED",
  CONTRACT_ERROR = "CONTRACT_ERROR",
  RESTIT_IN_PROGRESS = "RESTIT_IN_PROGRESS",
  UNKNOWN_ACCOUNT = "UNKNOWN_ACCOUNT",
  INVALID_BRAND = "INVALID_BRAND",
  OLD_PASSWORD = "OLD_PASSWORD",
  UNKNOWN_USER = "UNKNOWN_USER",
  NO_AGENDA = "NO_AGENDA",
  RESTIT_ALREADY_TODO = "RESTIT_ALREADY_TODO",
  RESTIT_ALREADY_DONE = "RESTIT_ALREADY_DONE",
  INVALID_MODEL = "INVALID_MODEL",
  MUST_CHANGE_PASSWORD = "MUST_CHANGE_PASSWORD",
}

export enum ERRORS_META {
  NOT_ACTIVE = "NOT_ACTIVE",
  TOO_RECENT = "TOO_RECENT",
  NOT_FOUND = "NOT_FOUND",
  DEFAULT_ERROR = "DEFAULT_ERROR",
  SINISTER = "SINISTER",
  INVALID_CONTRACT_TYPE = "INVALID_CONTRACT_TYPE",
  CLIENT_BLOCKED = "CLIENT_BLOCKED",
  INVALID_MODEL = "INVALID_MODEL",
  INVALID_BRAND = "INVALID_BRAND",
}

export enum GENERAL_CONDITION {
  CONFORME = "CONFORME",
  DAMAGED = "DAMAGED",
  HAIL = "HAIL",
  UNKNOWN = "UNKNOWN",
}

export enum OBSERVATION {
  REGISTRATION_CARD = "REGISTRATION_CARD",
  DUPLICATE_KEYS = "DUPLICATE_KEYS",
  NO_SIGNATORY = "NO_SIGNATORY",
  RECEPTIONIST = "RECEPTIONIST",
  NOT_MOVING = "NOT_MOVING",
  MASS_RETAIL = "MASS_RETAIL",
}

export const CONFIGURATION_FILE = "/config.json"
