import { Grid, Paper } from "@mui/material"
import { styled } from "@mui/material/styles"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

export const Photo = styled("img")({
  width: "100%",
  objectFit: "contain",
  boxSizing: "border-box",
  cursor: "pointer",
  display: "flex",
  alignItems: "flex-start",
})

export const PhotoContainer = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

export const Container = styled(Paper)({
  backgroundColor: "white",
  zIndex: 10,
  boxShadow: "none",
  overflow: "hidden",
  minWidth: "80%",
})

export const ModalContainer = styled("div")({
  width: "100%",
  height: "90vh",
  backgroundColor: "rgba(255,255,255,1)",
  [`@media (max-width: 1400px)`]: {
    height: "80vh",
  },
  [`@media (max-width: 1200px)`]: {
    height: "70vh",
  },
  [`@media (max-width: 800px)`]: {
    height: "60vh",
  },
  display: "flex !important",
  alignItems: "center",
  justifyContent: "center",
})

export const ModalPhoto = styled("img")({
  borderRadius: "4px",
  maxHeight: "100%",
  maxWidth: "100%",
  cursor: "grab",
})

export const Icon = styled("div")({
  fontSize: 26,
  marginRight: "4px",
  display: "flex",
})

export const Card = styled(Grid)(({ theme }) => ({
  backgroundColor: COLORS.GREY_BG,
  height: "fit-content",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

export const IconContainer = styled("div")(() => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  fontSize: 16,
  fontFamily: fonts.bold,
  lineHeight: "10px",
  right: 0,
}))

export const Link = styled("a")(() => ({
  textDecoration: "none",
  width: "100%",
}))
