import * as React from "react"

import { Article, Check, DirectionsCar, Key, Storefront, Warning } from "@mui/icons-material"
import { Box, Skeleton } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { FormattedMessage, useIntl } from "react-intl"
import { IntlShape } from "react-intl/src/types"
import { useNavigate } from "react-router-dom"

import dekra from "assets/images/dekra.svg"
import renault from "assets/images/renault_logo.svg"
import { COLORS } from "assets/styles/colors"
import StatusLogo from "components/statusLogo"
import * as T from "components/table/Table.styles"
import type { Restitution } from "types/common.types"
import { DOCUMENT_STATUS, EXPERTS, OBSERVATION, RESTITUTION_STATUS } from "types/enums.types"
import { formatDateTime, formatMileage, formatNull, formatPrice, getNoSignatoryKey, getNoSignatoryWarning } from "utils/search.utils"

import * as S from "./Search.styles"

type Key = {
  name: string
  align: "center" | "left"
}

const keys: Key[] = [
  { name: "restitution.contractId", align: "left" },
  { name: "restitution.licensePlate.light", align: "left" },
  { name: "restitution.mileage", align: "left" },
  { name: "restitution.version", align: "left" },
  { name: "restitution.contractType.light", align: "left" },
  { name: "restitution.status", align: "left" },
  { name: "restitution.tenant.name", align: "left" },
  { name: "restitution.receptionDate", align: "left" },
  { name: "restitution.receptionPlace", align: "left" },
  { name: "restitution.totalPrice", align: "left" },
  { name: "restitution.observations", align: "center" },
]

interface Props {
  results?: Array<Restitution>
  nbResults: number
}

export const getObservationIcon = (observation: OBSERVATION, value: DOCUMENT_STATUS | boolean) => {
  switch (observation) {
    case OBSERVATION.REGISTRATION_CARD:
      return <Article fontSize="inherit" htmlColor={value === DOCUMENT_STATUS.PRESENT ? "#000" : COLORS.RED} />
    case OBSERVATION.DUPLICATE_KEYS:
      return <Key fontSize="inherit" htmlColor={value === DOCUMENT_STATUS.PRESENT ? "#000" : COLORS.RED} />
    case OBSERVATION.NO_SIGNATORY:
      return value ? <Warning fontSize="inherit" htmlColor={COLORS.RED} /> : <Check fontSize="inherit" />
    case OBSERVATION.RECEPTIONIST:
      return value ? (
        <S.Logo src={dekra} alt="expertise-logo" width="20px" />
      ) : (
        <div>
          <S.RenaultLogo src={renault} alt="expertise-logo" />
        </div>
      )
    case OBSERVATION.NOT_MOVING:
      return value ? (
        <DirectionsCar fontSize="inherit" htmlColor={COLORS.RED} sx={{ transform: "rotate(135deg)" }} />
      ) : (
        <DirectionsCar fontSize="inherit" />
      )
    case OBSERVATION.MASS_RETAIL:
      return <Storefront fontSize="inherit" />
    default:
      return <div />
  }
}

const hideObservation = (r: Restitution) =>
  r.status === RESTITUTION_STATUS.TODO || r.status === RESTITUTION_STATUS.IN_PROGRESS || r.status === RESTITUTION_STATUS.CANCELLED

const renderObservations = (r: Restitution, intl: IntlShape) => (
  <Box display="flex" fontSize={20}>
    <S.Tooltip title={r?.receptionist?.company ?? ""}>
      {getObservationIcon(OBSERVATION.RECEPTIONIST, r?.receptionist?.company?.toUpperCase() === EXPERTS.DEKRA)}
    </S.Tooltip>
    {!hideObservation(r) && (
      <S.Tooltip title={intl.formatMessage({ id: `tooltip.registrationCard.${r.registrationCard}` })}>
        {getObservationIcon(OBSERVATION.REGISTRATION_CARD, r.registrationCard)}
      </S.Tooltip>
    )}
    {!hideObservation(r) && (
      <S.Tooltip title={intl.formatMessage({ id: `tooltip.duplicateKeys.${r.duplicateKeys}` })}>
        {getObservationIcon(OBSERVATION.DUPLICATE_KEYS, r.duplicateKeys)}
      </S.Tooltip>
    )}
    {!hideObservation(r) && (
      <S.Tooltip title={intl.formatMessage({ id: getNoSignatoryKey(r.noSignatory, r.signatoryRefused) })}>
        {getObservationIcon(OBSERVATION.NO_SIGNATORY, getNoSignatoryWarning(r.noSignatory, r.signatoryRefused))}
      </S.Tooltip>
    )}
    {!hideObservation(r) && (
      <S.Tooltip title={intl.formatMessage({ id: `tooltip.notMoving.${r.notMoving}` })}>
        {getObservationIcon(OBSERVATION.NOT_MOVING, r.notMoving)}
      </S.Tooltip>
    )}
    {!hideObservation(r) && r.massRetail && (
      <S.Tooltip title={intl.formatMessage({ id: "tooltip.massRetail" })}>
        {getObservationIcon(OBSERVATION.MASS_RETAIL, r.massRetail)}
      </S.Tooltip>
    )}
  </Box>
)

const SearchTable = ({ results, nbResults }: Props) => {
  const navigate = useNavigate()

  const intl = useIntl()
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {keys.map((k) => (
              <T.TableCellTitle align={k.align} key={k.name}>
                <FormattedMessage id={k.name} />
              </T.TableCellTitle>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {results &&
            results.length > 0 &&
            results.map((row) => (
              <T.TableRow key={row.id} onClick={() => navigate(`/details/${row.id}`)} fontSize={14}>
                <T.TableCell component="th" scope="row">
                  {row.contractId}
                </T.TableCell>
                <T.TableCell scope="row">
                  <S.NoWrap>{formatNull(row.licensePlate)}</S.NoWrap>
                </T.TableCell>
                <T.TableCell>{formatMileage(intl, row.mileage)}</T.TableCell>
                <T.TableCell style={{ maxWidth: 160 }}>{formatNull(`${row.brand} ${row.model} ${row.version}`)}</T.TableCell>
                <T.TableCell>{formatNull(intl.formatMessage({ id: `restitution.contractType.${row.contractType}` }))}</T.TableCell>
                <T.TableCell>
                  <Box display="flex" alignItems="center">
                    <StatusLogo status={row.status} />
                    <S.StatusLabel>{intl.formatMessage({ id: `filter.status.${row.status}` })}</S.StatusLabel>
                  </Box>
                </T.TableCell>
                <T.TableCell>{formatNull(row.tenant.lastName)}</T.TableCell>
                <T.TableCell>{formatDateTime(intl, row.combinedDate ?? row.agendaDate)}</T.TableCell>
                <T.TableCell>{formatNull(row.receptionPlace)}</T.TableCell>
                <T.TableCell>{formatPrice(intl, row.totalDamages)}</T.TableCell>
                <T.TableCell>{renderObservations(row, intl)}</T.TableCell>
              </T.TableRow>
            ))}
          {!results &&
            nbResults === -1 &&
            ["1", "2", "3", "4", "5"].map((r) => (
              <T.TableRow key={r}>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
                <T.TableCell>
                  <Skeleton height={20} animation="wave" />
                </T.TableCell>
              </T.TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SearchTable
