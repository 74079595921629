import React, { useCallback, useEffect, useState } from "react"

import DatePicker from "@mui/lab/DatePicker"
import type { TextFieldProps } from "@mui/material"
import { TextField } from "@mui/material"
import { styled } from "@mui/material/styles"
import dateFormat from "date-fns/format"
import debounce from "lodash/debounce";
import { useIntl } from "react-intl"

import { useReduxDispatch } from "store"
import actions from "store/actions"
import { RangeDateFilter as RangeDateFilterType } from "types/common.types"


export const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  backgroundColor: "white",
  maxHeight: "300px",
  zIndex: 10,
}))

export const DateContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
}))

interface Props {
  filter: RangeDateFilterType
  name: string
  direction?: "column" | "row"
  size?: "medium" | "small"
}

/*
  Use local state to handle date change while format is incorrect, then trigger onFilterChange action
 */
const RangeDateFilter = ({ filter, name, direction = "column", size = "medium" }: Props) => {
  const { min, max, selectedMin, selectedMax } = filter
  const dispatch = useReduxDispatch()

  const intl = useIntl()
  const initialState = {
    min: selectedMin ? new Date(selectedMin) : min && new Date(min),
    max: selectedMax ? new Date(selectedMax) : max && new Date(max),
  }

  const [dates, setDates] = useState(initialState)

  /* Reset state on filter change */
  useEffect(() => {
    setDates(initialState)
    // eslint-disable-next-line
  }, [filter])

  const handleFilterChange = useCallback(debounce( (d: { min?: "" | Date | null; max?: "" | Date | null }) => {
    const minMax = d.min ? "min" : "max"
    const value = d[minMax]
    if (value) {
      const newDates = { ...dates, [minMax]: d[minMax] }
      setDates(newDates)

      if (
        !newDates.min ||
        !newDates.max ||
        isNaN(newDates.min.getTime()) ||
        isNaN(newDates.max.getTime()) ||
        newDates.min.getTime() > newDates.max.getTime()
      ) {
        return
      }

      dispatch(actions.search.changeRange({ [minMax]: dateFormat(value, "YYY-MM-dd"), filter: name }))
    }
  }, 300), [dates, dispatch, name])

  return (
    <Container sx={{ flexDirection: direction }}>
      <DateContainer>
        <DatePicker
          label={intl.formatMessage({
            id: "search.filter.date.min",
          })}
          inputFormat="dd/MM/yyyy"
          maxDate={dates.max}
          value={dates.min}
          InputAdornmentProps={{ position: "start" }}
          onChange={(date: "" | Date | null) => {
            handleFilterChange({ min: date })
          }}
          renderInput={(params: TextFieldProps) => <TextField {...params} size={size} />}
        />
      </DateContainer>
      <DateContainer>
        <DatePicker
          label={intl.formatMessage({
            id: "search.filter.date.max",
          })}
          inputFormat="dd/MM/yyyy"
          value={dates.max}
          InputAdornmentProps={{ position: "start" }}
          onChange={(date: "" | Date | null) => {
            handleFilterChange({ max: date })
          }}
          renderInput={(params: TextFieldProps) => <TextField {...params} size={size} />}
        />
      </DateContainer>
    </Container>
  )
}

export default RangeDateFilter
