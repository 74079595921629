import { IntlShape } from "react-intl/src/types"

import { RangeDateFilter, RangeNumberFilter, TermFilter, TermSearchFilter } from "types/common.types"
import { FILTER_TYPES } from "types/enums.types"

/* Transform search query (complex object) to query param (a list of values), in order to query api */
/* searchQuery: the query object to transform  */
/* keys: object properties to transform */
export const searchFiltersToQueryParams = (filters: {
  [key: string]: TermFilter | TermSearchFilter | RangeNumberFilter | RangeDateFilter
}): Record<string, unknown> => {
  const queryParams: Record<string, unknown> = {}
  if (!filters) return queryParams

  Object.keys(filters).forEach((k) => {
    const filter = filters[k]

    switch (filter.type) {
      case FILTER_TYPES.TERM:
      case FILTER_TYPES.TERM_SEARCH:
        const array = filter.terms.filter((t) => t.checked).map((t) => t.term)
        if (array && array.length > 0) {
          queryParams[k] = array
        }
        break
      case FILTER_TYPES.RANGE_NUMBER:
        if (Number.isFinite(filter.selectedMax)) {
          queryParams[`${k}.max`] = filter.selectedMax
        }
        if (Number.isFinite(filter.selectedMin)) {
          queryParams[`${k}.min`] = filter.selectedMin
        }
        break

      case FILTER_TYPES.RANGE_DATE:
        if (filter.selectedMax) {
          queryParams[`${k}.max`] = filter.selectedMax
        }
        if (filter.selectedMin) {
          queryParams[`${k}.min`] = filter.selectedMin
        }
        break

      default:
        // queryParams[k] = filters[k]
        break
    }
  })

  return queryParams
}

export const isFilterSelected = (filter: TermFilter | TermSearchFilter | RangeNumberFilter | RangeDateFilter): boolean => {
  if (!filter) {
    return false
  }

  switch (filter.type) {
    case FILTER_TYPES.TERM:
    case FILTER_TYPES.TERM_SEARCH:
      return isAnyTermChecked(filter)
    case FILTER_TYPES.RANGE_NUMBER:
    case FILTER_TYPES.RANGE_DATE:
      return !!filter.selectedMax || !!filter.selectedMin
    default:
      return false
  }
}

export const isAnyTermChecked = (filter: TermFilter | TermSearchFilter) => {
  return filter.terms.reduce((prev, cur) => (cur.checked ? true : prev), false)
}

export const resetFilter = (
  filter: TermFilter | TermSearchFilter | RangeNumberFilter | RangeDateFilter
): TermFilter | TermSearchFilter | RangeNumberFilter | RangeDateFilter => {
  switch (filter.type) {
    case FILTER_TYPES.TERM:
    case FILTER_TYPES.TERM_SEARCH:
      return {
        ...filter,
        terms: filter.terms.map((t) => ({ ...t, checked: false })),
      }
    case FILTER_TYPES.RANGE_NUMBER:
    case FILTER_TYPES.RANGE_DATE:
      return {
        ...filter,
        selectedMax: undefined,
        selectedMin: undefined,
      }
    default:
      return filter
  }
}

export const formatNull = (value?: unknown) => (value ? value + "" : "-")
export const formatNullNumber = (value?: number) => (value || value === 0 ? value + "" : "-")
export const formatDate = (intl: IntlShape, value?: unknown) => {
  if (typeof value === "string" || value instanceof Date) {
    return intl.formatDate(new Date(value))
  }

  return "-"
}

const printDate = (intl: IntlShape, d: number | string | Date) =>
  `${intl.formatDate(new Date(d))} à ${intl.formatDate(new Date(d), { timeStyle: "medium" })}`

export const formatDateTime = (intl: IntlShape, value?: unknown) => {
  if (typeof value === "string") {
    if (!value.endsWith("Z")) {
      return printDate(intl, value + "Z")
    }
    return printDate(intl, value)
  }
  if (value instanceof Date || typeof value === "number") {
    return printDate(intl, value)
  }

  return "-"
}

export const formatMileage = (intl: IntlShape, value?: unknown) => {
  if (typeof value === "number" && Number.isFinite(value)) {
    return intl.formatNumber(value) + " km"
  }

  return "-"
}

export const formatPrice = (intl: IntlShape, value?: unknown) => {
  if (typeof value === "number" && Number.isFinite(value)) {
    return intl.formatNumber(value, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: value % 1 === 0 ? 0 : 2,
    })
  }

  return "-"
}

export const formatDurationDetail = (intl: IntlShape, value: unknown) => {
  if (!value) {
    return "-"
  }

  if (typeof value === "number" && Number.isFinite(value)) {
    const durationHours = Math.floor(value / 60)
    const durationMinutes = value % 60

    const renderHours = (hours: number) =>
      intl.formatNumber(hours, {
        style: "unit",
        unit: "hour",
        unitDisplay: "narrow",
      })

    const renderMinutes = (minutes: number) =>
      intl.formatNumber(minutes, {
        style: "unit",
        unit: "minute",
        unitDisplay: "narrow",
      })

    if (durationHours <= 0 && durationMinutes <= 0) {
      return renderMinutes(durationMinutes)
    }
    if (durationHours <= 0 && durationMinutes > 0) {
      return renderMinutes(durationMinutes)
    }
    if (durationHours > 0 && durationMinutes <= 0) {
      return renderHours(durationHours)
    }

    return `${renderHours(durationHours)} ${renderMinutes(durationMinutes)}`
  }
}

export const formatDuration = (intl: IntlShape, value: unknown) => {
  if (!value) {
    return "-"
  }

  if (typeof value === "number" && Number.isFinite(value)) {
    const h = intl.formatNumber(value)

    return `${h} h`
  }
}

export const searchParamToObject = (searchParams: URLSearchParams) => {
  const result: Record<string, string | string[]> = {}
  searchParams.forEach((val, key) => {
    if (result[key] && Array.isArray(result[key])) {
      ;(result[key] as string[]).push(val)
    } else if (result[key]) {
      result[key] = [result[key] as string, val]
    } else {
      result[key] = val
    }
  })
  return result
}

export const cleanLicensePlate = (query: string): string => {
  const cleanQuery = query.replace(/[-,\s]/g, "").toUpperCase()
  if (query.length > 6) {
    return `${cleanQuery.slice(0, 2)}-${cleanQuery.slice(2, 5)}-${cleanQuery.slice(5)}`
  } else if (cleanQuery.length > 2) {
    return `${cleanQuery.slice(0, 2)}-${cleanQuery.slice(2)}`
  }
  return cleanQuery
}

export const getNoSignatoryKey = (noSignatory?: boolean, signatoryRefused?: boolean): string => {
  if (noSignatory === undefined && signatoryRefused === undefined) {
    return "tooltip.noSignatory.undefined"
  }

  if (noSignatory) {
    return "tooltip.noSignatory.true"
  }

  if (signatoryRefused) {
    return "tooltip.signatoryRefused.true"
  }

  return "tooltip.noSignatory.false"
}

export const getNoSignatoryWarning = (noSignatory?: boolean, signatoryRefused?: boolean): boolean => {
  if (noSignatory === undefined && signatoryRefused === undefined) {
    return true
  }

  if (noSignatory) {
    return true
  }

  if (signatoryRefused) {
    return true
  }

  return false
}
