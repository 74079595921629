const forms = {
  "form.authentication.login": "Email",
  "form.authentication.password": "Mot de passe",
  "form.authentication.button.submit": "Connexion avec identifiants",
  "form.agenda.event.title": "Événement",
  "form.agenda.signatory.title": "Signataire",
  "form.agenda.date": "Date",
  "form.agenda.comment": "Commentaire",
  "form.agenda.receptionist": "Réceptionnaire",
  "form.agenda.noUser": "Non connu",
  "form.agenda.office": "Lieu de la restitution",
  "form.agenda.signatoryFirstName": "Prénom",
  "form.agenda.signatoryLastName": "Nom",
  "form.agenda.signatoryEmail": "E-mail",
  "form.agenda.signatoryPhone": "Téléphone",
  "form.agenda.noSignatory": "Aucun signataire ne sera présent",
  "form.agenda.button.submit": "Créer le rendez-vous",
  "form.error.required": "Ce champ est requis",
  "form.error.dateTimeFormat": "La date n'est pas valide",
}

export default forms
