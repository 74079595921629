import { AxiosError } from "axios"

import { APIError } from "types/api.types"

export const getErrorMessages = (error: AxiosError<APIError>): string[] => {
  const type = error?.response?.data?.error
  const meta = error?.response?.data.meta

  if (meta && Array.isArray(meta) && meta.length > 0) {
    return meta.map((m) => `ERROR.${type}.${m}`)
  }

  if (type) {
    return [`ERROR.${type}`]
  }

  return ["error.default"]
}
